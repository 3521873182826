import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { HTMLContent } from '../components/Content';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Layout from '../components/Layout';
import Accordion from '../components/shared/Accordion';
import Countdown from '../components/shared/Countdown';
import './editais-page.scss';

export const EditaisPageTemplate = ({
  deadline,
  downloads,
  contact,
  cta,
  banner,
  timeline,
  faq
}) => {
  const currDate = new Date();
  const startDate = new Date(deadline.startDate);
  const endDate = new Date(deadline.date);
  const formattedEndDate = endDate.toLocaleDateString('pt-BR');
  const longEndDate = endDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' });

  // const openEdital = currDate.getTime() >= startDate.getTime() && currDate.getTime() < endDate.getTime();
  const openEdital = currDate.getTime() < endDate.getTime();
  const year = currDate.getFullYear();

  let bannerHeader, countdown;

  if (openEdital) {
    countdown = (
      <header className="deadline__header">
        <h2>{deadline.headline}</h2>
        <Countdown deadline={formattedEndDate}></Countdown>
        <span className="text">{deadline.text} <b>{formattedEndDate}</b></span>
      </header>
    );

    bannerHeader = `O Edital ${year}.1 está com as inscrições abertas`;
  } else {
    countdown = (
      <header className="deadline__header" style={{ justifyContent: 'center' }}>
        <div className="countdown">
          <span className='countdown__ended'>INSCRIÇÕES ENCERRADAS</span>
        </div>
      </header>
    );

    bannerHeader = `O Edital ${year} fechou.`;
  }

  return (
    <div className="editais">
      <section className="deadline__container">
        <div className="deadline__left">
          {countdown}
          <article className="deadline__body">
            {openEdital && (
              <div className="deadline__downloads">
                <h4>Downloads disponíveis:</h4>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSeBf5g19RQrk616cTdc5PJlkAmEhTfIMBkw-8MGtyZNY6lMEw/viewform" target="_blank">
                  <span>Formulário para PDF e Templates do Edital</span>
                </a>
                <a href="https://editalamigosdapoli.app.gradus-softwares.com.br/#/login" target="_blank">
                  <span>Formulário para Inscrição no Edital</span>
                </a>
                {/* {downloads.map(d => <a href={d.url} download>
                  <span>{d.text}</span>
                </a>)} */}
              </div>
            )
            }
            <div className="deadline__doubts">
              <h4>Dúvidas:</h4>
              <a href={'mailto:' + contact.email}>
                <span>{contact.email}</span>
              </a>
            </div>
            {openEdital && (
              <Link to="https://editalamigosdapoli.app.gradus-softwares.com.br/">
                <button className="button is-primary">{cta}</button>
              </Link>
            )
            }
          </article>
        </div>
        <div className="deadline__right">
          <PreviewCompatibleImage imageInfo={{ image: deadline.image }} />
        </div>
      </section>
      <section className="information__container">
        <div className="information__left">
          <header>{bannerHeader}</header>
          <article>
            <p>Lembre-se que o período de inscrições se encerra no dia <b>{longEndDate}</b> às 23:59</p>
            <p>Para que a inscrição seja considerada válida é necessário que sejam submetidos, no momento da inscrição, cronograma físico-financeiro e o link do vídeo de inscrição.</p>
            <p>Ao submeter o projeto verifique o recebimento do <b>e-mail de confirmação!</b></p>
          </article>
          <footer>{banner.footer}</footer>
        </div>
        <div className="information__right">
          <h3>{banner.categories.title}</h3>
          <div className="information__categories">
            {banner.categories.items.map(c => <div className="information__category">
              <div>
                <PreviewCompatibleImage className="image" imageInfo={{ image: c.image, style: { margin: '0 auto' } }} />
                <span>{c.text}</span>
              </div>
            </div>)}
          </div>
        </div>
      </section>
      <section className="timeline__container">
        <h2 className="timeline__header">Cronograma</h2>
        <div className="timeline__phases">
          {timeline.map(t => <div className="timeline__phase">
            <span className="timeline__headline">{t.headline}</span>
            <div className="timeline__items">
              {t.items.map(it => <div className="timeline__item">
                <span className="timeline__item-number">{it.number}</span>
                <span className="timeline__item-date">{it.date}</span>
                <span className="timeline__item-text">{it.text}</span>
              </div>)}
            </div>
          </div>)}
        </div>
      </section>
      <section className="faq__container">
        <header className="faq__header">FAQ</header>
        <article className="faq__body">
          {faq.map(f =>
            <Accordion title={f.question} content={`<p>${f.answer}</p>`}></Accordion>
          )}
        </article>
      </section>
      <section className="old__container">
        <h2 className="old__header">Projetos apoiados em 2022</h2>
        <article className="old__body">
          <h4>Em 2022, o Amigos da Poli apoiou 42 projetos por meio de seu edital</h4>
        </article>
      </section>
    </div>
  );
};

EditaisPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  deadline: PropTypes.object.isRequired,
  downloads: PropTypes.object.isRequired,
  overrideDownload: PropTypes.bool,
  contact: PropTypes.object.isRequired,
  cta: PropTypes.object.isRequired,
  banner: PropTypes.object.isRequired,
  timeline: PropTypes.object.isRequired,
  faq: PropTypes.object.isRequired
};

const EditaisPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <EditaisPageTemplate {...frontmatter} />
    </Layout>
  );
};

EditaisPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EditaisPage;

export const editaisPageQuery = graphql`
  query editaisPageAndEditaisPage($id: String!) {
    markdownRemark(id: {eq: $id }) {
      html
      frontmatter {
        title
        deadline {
          startDate
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          headline
          text
          date
        }
        downloads {
          text
          name
          url
        }
        contact {
          email
        }
        cta
        banner {
          footer
          categories {
          title
            items {
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              text
            }
          }
        }
        timeline {
          headline
          items {
          number
            date
            text
          }
        }
        faq {
          question
          answer
        }
      }
    }
  }
`;
