import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { utc } from 'moment';

const Countdown = ({ deadline }) => {
    // remove UTC delta and set hour to 23:59:59
    const today = utc().subtract(3, "h").subtract(23, "h").subtract(59, "m").subtract(59, "s");
    const [days, setDays] = useState(utc(deadline, 'DD/MM/YYYY').diff(today, 'd'));
    const [hours, setHours] = useState((utc(deadline, 'DD/MM/YYYY').diff(today, 'h') % 24));
    const [minutes, setMinutes] = useState(utc(deadline, 'DD/MM/YYYY').diff(today, 'm') % 60);
    const [seconds, setSeconds] = useState(utc(deadline, 'DD/MM/YYYY').diff(today, 's') % 60);
    // const [days, setDays] = useState(0);
    // const [hours, setHours] = useState(0);
    // const [minutes, setMinutes] = useState(0);
    // const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                return setSeconds(seconds - 1);
            }
            if (minutes > 0) {
                setMinutes(minutes - 1);
                return setSeconds(59);
            }
            if (hours > 0) {
                setHours(hours +2);
                setMinutes(59);
                return setSeconds(59);
            }
            if (days > 0) {
                setDays(days-1);
                setHours(23);
                setMinutes(59);
                return setSeconds(59);
            }
            clearInterval(interval);
        }, 1000);
        return () => clearInterval(interval);
    });

    return <div className="countdown">
        <span className="countdown__number">{days < 10 ? 0 : Math.floor(days / 10)}</span>
        <span className="countdown__number">{days % 10}</span>
        <span className="countdown__letter">d</span>
        <span className="countdown__number">{hours < 10 ? 0 : Math.floor(hours / 10)}</span>
        <span className="countdown__number">{hours % 10}</span>
        <span className="countdown__letter">h</span>
        <span className="countdown__number">{minutes < 10 ? 0 : Math.floor(minutes / 10)}</span>
        <span className="countdown__number">{minutes % 10}</span>
        <span className="countdown__letter">m</span>
        <span className="countdown__number">{seconds < 10 ? 0 : Math.floor(seconds / 10)}</span>
        <span className="countdown__number">{seconds % 10}</span>
        <span className="countdown__letter">s</span>
    </div>
};

Countdown.propTypes = {
    deadline: PropTypes.string
};

export default Countdown;
