import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import angleDown from '../../img/angle-down.svg';

import './Accordion.scss';

const Accordion = ({ title, content }) => {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const bodyContent = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive !== "active" ? "active" : "inactive");
        setHeightState(
            setActive === "active" ? "0px" : `${bodyContent.current.scrollHeight}px`
        );
    }

    return <section className="accordion__container">
        <header className="accordion__header">
            <button className={`accordion__button ${setActive}`} onClick={toggleAccordion}>
                {title}
                <img className="accordion__angle" src={angleDown} />
            </button>
        </header>
        <article
            ref={bodyContent}
            className="accordion__body"
            style={{ maxHeight: `${setHeight}` }}
            dangerouslySetInnerHTML={{ __html: content }}>
        </article>
    </section>
};

Accordion.propTypes = {
    title: PropTypes.string,
    content: PropTypes.node
};

export default Accordion;